<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        :label="$t('includeTax')"
        filled
        dense
        :no-data-text="$t('taxBracketsForThisOrgnanizationHaveNotBeenChosenYet')"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
	name: "VatSelection",
	computed: {
	}
}
</script>

<style scoped>

</style>